import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import himg from '../../images/slider/3.png';
import himg from '../../images/slider/sriram_home_page.jpg'

import icon1 from '../../images/slider/medal.svg'
import icon2 from '../../images/slider/stick-man.svg'

// import cImg1 from '../../images/slider/client1.png'
// import cImg2 from '../../images/slider/client2.png'
// import cImg3 from '../../images/slider/client3.png'
// import cImg4 from '../../images/slider/client4.png'

import cImg1 from '../../images/slider/client1.jpeg'
import cImg2 from '../../images/slider/client2.jpeg'
import cImg3 from '../../images/slider/client3.jpeg'
import cImg4 from '../../images/slider/client4.jpeg'
import cImg5 from '../../images/slider/client5.jpeg'

const settings = {
    dots: false,
    arrows: false,
    speed: 1200,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
};

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Hero3 = () => {

    const handleSubmit = (e) => {
        e.preventDefault();

        var options = {
            key: "rzp_live_cYZydtfWzO3MrZ",
            key_secret: "axVI9xOAWDCNGiamNhHXxysg",
            amount: 99*100,
            currency: "INR",
            name: "Re10x",
            description: "Become a Registered Valuer",
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                console.log("response.razorpay_payment_id")
                console.log(response.razorpay_payment_id)
                console.log("response")
                console.log(response)
            },
            // prefill: {
            //     name: "Shyamsundar",
            //     email: "shyam2785@gmail.com",
            //     contact: "9884730355"
            // },
            notes: {
                address: "Re10x.in Corporate office"
            },
            modal: {
                ondismiss: function() {
                    console.log("Checkout form closed");
                }
            },
            // theme: {
            //     color: "linear-gradient(90deg, #ED6B37 11.32%, #EC180C 95.28%)"
            // }
        };
        var pay = new window.Razorpay(options);
        pay.on("payment.failed", function (response) {
            console.log("Payment failed response")
            console.log(response)
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          });
        pay.open();
    };
    
    return (
        <section className="static-hero-s2">
            <div className="hero-container">
                <div className="hero-inner" style={{top: "-40px"}}>
                    <div className="container-fluid">
                        <div className="hero-content">
                            <div data-swiper-parallax="300" className="slide-title">
                                <h2>Join & Become a <span>Register Valuer</span></h2>
                                {/* <h2>Exclusive <span>3 HRs</span> Online Workshop</h2> */}
                            </div>
                            <div data-swiper-parallax="400" className="slide-text">
                                <p>IBBI - Land and Building, Plant & Machinery, Securities & Financial Assets.</p>
                            </div>
                            <div className="clearfix"></div>
                            <div data-swiper-parallax="500" className="slide-btns">
                                <Link to="" onClick={handleSubmit} className="theme-btn" style={{fontFamily: '"Nunito Sans", sans-serif', fontWeight: "600"}}>Join Now at just ₹99/-</Link>
                            </div>
                            <div className="politian-pic">
                                <div className="inner">
                                    {/* <img src={himg} alt="" /> */}
                                    <img src={himg} alt="" />
                                </div>
                                <div className="back-shape"></div>
                                <div className="award">
                                    <div className="icon"><i><img src={icon1} alt="" /></i></div>
                                    <div className="content">
                                        <h5>India's Leading Register Valuer</h5>
                                    </div>
                                </div>
                                <div className="wpo-supporter">
                                    <div className="wpo-supporter-text">
                                        <div className="icon"><i><img src={icon2} alt="" /></i>
                                        </div>
                                        <div className="content">
                                            <h3>2K</h3>
                                            <p>Attendees</p>
                                        </div>
                                    </div>
                                    <div className="wpo-supporter-img">
                                        <ul className="wpo-supporter-slide">
                                            <Slider {...settings}>
                                                <li><img
                                                    src={cImg1} alt="" /></li>
                                                <li><img
                                                    src={cImg2} alt="" /></li>
                                                <li><img
                                                    src={cImg3} alt="" /></li>
                                                <li><img
                                                    src={cImg4} alt="" /></li>
                                                <li><img
                                                    src={cImg5} alt="" /></li>
                                            </Slider>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}



export default Hero3;