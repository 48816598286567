import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom'
import VerifiedSharpIcon from '@mui/icons-material/VerifiedSharp';
import handleSubmit from '../footer/Footer';
import Button from '@mui/material/Button';

const CapabilitieSection = () => {
    const data = [
        "3 Hrs Session on Road to Become Registered Valuer.",
        "Insights On How To Clear The Exam.",
        "How To Empanel With Bank For Valuation.",
        "RVO Registration.",
        "NISM Registration And Enrollment For Exam.",
        "How To Find The First Assignment."
    ];

    const handleSubmit = (e) => {
        e.preventDefault();

        var options = {
            key: "rzp_live_cYZydtfWzO3MrZ",
            key_secret: "axVI9xOAWDCNGiamNhHXxysg",
            amount: 99*100,
            currency: "INR",
            name: "Re10x",
            description: "Become a Registered Valuer",
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                console.log("response.razorpay_payment_id")
                console.log(response.razorpay_payment_id)
                console.log("response")
                console.log(response)
            },
            // prefill: {
            //     name: "Shyamsundar",
            //     email: "shyam2785@gmail.com",
            //     contact: "9884730355"
            // },
            notes: {
                address: "Re10x.in Corporate office"
            },
            modal: {
                ondismiss: function() {
                    console.log("Checkout form closed");
                }
            },
            // theme: {
            //     color: "linear-gradient(90deg, #ED6B37 11.32%, #EC180C 95.28%)"
            // }
        };
        var pay = new window.Razorpay(options);
        pay.on("payment.failed", function (response) {
            console.log("Payment failed response")
            console.log(response)
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          });
        pay.open();
    };

    return (
        <div className="wpo-service-single-area section-padding">
            <SectionTitle subTitle={'What'} Title={'What You Will Learn In 3 Hrs?'} />
            <div className="container">
                <Grid container spacing={2}>
                    {
                        data && data.length > 0 && data.map((item, index) => (
                            <Grid kty={index} item xs={12} md={6} lg={6}>
                                <Paper square={false} variant="elevation" elevation={24}
                                    sx={{ borderRadius: 2, gap: 2, px: 4, py: 2 }}
                                >
                                    <VerifiedSharpIcon sx={{ marginRight: 1, color: '#ff4a17' }} />
                                    {item}
                                </Paper>
                            </Grid>
                        ))
                    }
                </Grid>
                <div className="slide-btns pt-5 d-flex justify-content-center">
                    <Link className="theme-btn" to=""
                        style={{fontFamily: '"Nunito Sans", sans-serif', fontWeight: "600"}}
                        onClick={handleSubmit}
                    >
                        Book your spot now at just ₹99/-
                    </Link>
                    {/* <Button className="theme-btn" 
                        style={{fontFamily: '"Nunito Sans", sans-serif', fontWeight: "600"}}
                        onClick={handleSubmit}
                    >
                        Book your spot now at just ₹99/-
                    </Button> */}
                </div>
            </div>
        </div>
    );
};

export default CapabilitieSection;
