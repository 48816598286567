import React from "react";
import { Link } from 'react-router-dom';
import SectionTitle from '../SectionTitle/SectionTitle';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const data = [
    {
        icon: "fi flaticon-charity",
        title: "Knowledge",
        desc: "Assured knowledge Worth of Rs. 1,50,000/-"
    },
    {
        icon: "fi flaticon-conference-1",
        title: "Become Valuer",
        desc: "You Unlock to Become Registered Valuer."
    },
    {
        icon: "fi flaticon-community",
        title: "Business Growth",
        desc: "Your Business Growth with Increases Revenue."
    },
    {
        icon: "fi flaticon-goal",
        title: "New Area",
        desc: "Unlock the new area of practice."
    }
]

const Features2 = (props) => {
    return (
        <div className="wpo-features-s2 pb-0 pt-4">
            <div className="container">
                <SectionTitle subTitle={'Join Our Webinar'} Title={'The Impact of Joining The Webinar'} />
                <div className="features-wrap">
                    <div className="row">
                        {
                            data && data.length > 0 && data.map((item, index) => (
                                <div key={index} className="col-lg-3 col-md-6 col-12">
                                    <div className="feature-item">
                                        <div className="features-wrapper">
                                            <div className="icon">
                                                <div className="features-dot">
                                                    <div className="dots"></div>
                                                </div>
                                                <i className={item?.icon}></i>
                                            </div>
                                            <div className="feature-text">
                                                <h2><Link to="">{item?.title}</Link></h2>
                                                <p>{item?.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features2;